import { keyBy } from "lodash";

export type TempTypes = {
  categoryId: number;
  categoryCode: string;
  color: string;
  name: string;
  imageSrc?: string;
  size?: string;
  adjust?: string;
  emoji?: string;
};

export const categoryAssets: TempTypes[] = [
  {
    categoryId: 0,
    categoryCode: "",
    name: "전체 카테고리",
    color: "#ffe0ea",
    imageSrc: undefined,
    emoji: undefined,
  },
  {
    categoryId: 1,
    categoryCode: "0100",
    name: "뷰티",
    color: "#ffe0ea",
    imageSrc: "/image/lotion_bottle_3d.png",
    emoji: "👄",
  },
  {
    categoryId: 2,
    categoryCode: "0200",
    name: "패션",
    color: "#fbdfff",
    imageSrc: "/image/sunglasses_3d.png",
    emoji: "🎀",
  },
  {
    categoryId: 3,
    categoryCode: "0300",
    name: "푸드",
    color: "#ffa8a8",
    imageSrc: "/image/red_apple_3d.png",
    emoji: "🍚",
  },
  {
    categoryId: 4,
    categoryCode: "0400",
    name: "앱 서비스",
    color: "crimson",
    imageSrc: "/image/balloon_3d.png",
    emoji: "📱",
  },
  {
    categoryId: 5,
    categoryCode: "0500",
    name: "게임",
    color: "#ffd3d3",
    imageSrc: "/image/joystick_3d.png",
    emoji: "🕹️",
  },
  {
    categoryId: 6,
    categoryCode: "0600",
    name: "테크",
    color: "#8ce4ef",
    imageSrc: "/image/desktop_computer_3d.png",
    emoji: "🖥️",
  },
  {
    categoryId: 7,
    categoryCode: "0700",
    name: "리빙",
    color: "#fee3d9",
    imageSrc: "/image/door_3d.png",
    emoji: "🏠",
  },
  {
    categoryId: 8,
    categoryCode: "0800",
    name: "문화·취미",
    color: "#d4f8ff",
    imageSrc: "/image/tennis_3d.png",
    emoji: "🎞️",
  },
  {
    categoryId: 9,
    categoryCode: "0900",
    name: "건강·운동",
    color: "#d8f8a3",
    imageSrc: "/image/woman_in_lotus_position_3d_default.png",
    emoji: "💪",
  },
  {
    categoryId: 10,
    categoryCode: "1000",
    name: "교육·기술",
    color: "#e8b4bf",
    imageSrc: "/image/balloon_3d.png",
    emoji: "🏫",
  },
  {
    categoryId: 11,
    categoryCode: "1100",
    name: "여행",
    color: "#e8b4bf",
    imageSrc: "/image/world_map_3d.png",
    emoji: "🧳",
  },
  {
    categoryId: 12,
    categoryCode: "1200",
    name: "반려동물",
    color: "#ffebd1",
    imageSrc: "/image/dog_face_3d.png",
    emoji: "🐶",
  },
  {
    categoryId: 13,
    categoryCode: "1300",
    name: "금융·재테크",
    color: "#fff6c9",
    imageSrc: "/image/money_bag_3d.png",
    emoji: "💰",
  },
  {
    categoryId: 14,
    categoryCode: "1400",
    name: "자동차",
    color: "#d7e0ff",
    imageSrc: "/image/oncoming_automobile_3d.png",
    emoji: "🚘",
    // adjust: '-6px',
  },
];

export const categoryMap = keyBy(categoryAssets, "categoryCode");
