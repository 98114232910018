import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiPageBody,
  EuiPageHeader,
  EuiSpacer,
  EuiTitle
} from "@elastic/eui";
import {useParams} from "react-router-dom";
import {
  ExpectAudience,
  OrgAudience,
  PutChannelAudienceRequest,
  useGetChannelDetail,
  useGetExpectAudience,
  usePutChannelAudience
} from "../../api/apis";
import dayjs from "dayjs";
import {useEffect, useMemo, useState} from "react";
import {ChannelEntity} from "../../api/types";
import _, {every} from "lodash"


const ChannelDetail = () => {
  const {channelId} = useParams()
  const {data: channelDetail, isLoading} = useGetChannelDetail(Number(channelId))

  const [data, setData] = useState<ChannelEntity>()
  const [orgAudience, setOrgAudience] = useState<OrgAudience>({})
  const [expAudience, setExpAudience] = useState<ExpectAudience>({})

  const { refetch: fetchExpectAudience } = useGetExpectAudience(orgAudience)
  const { mutate: putChannelAudience } = usePutChannelAudience();

  useEffect(() => {
    if (!channelDetail) {
      return
    }

    setData(channelDetail.channel)

    if(channelDetail.channelAudience?.original) {
      setOrgAudience(channelDetail.channelAudience?.original)
    }
    if(channelDetail.channelAudience?.expect) {
      setExpAudience(channelDetail.channelAudience?.expect)
    }
  }, [channelDetail])

  const orgAgeSum = useMemo(() => {
    const {org13, org18, org25, org35, org45, org55, org65} = orgAudience
    return (Number(org13) || 0) + (Number(org18) || 0) + (Number(org25) || 0) + (Number(org35) || 0)
      + (Number(org45) || 0) + (Number(org55) || 0) + (Number(org65) || 0)
  }, [orgAudience])

  const orgGenderSum = useMemo(() => {
    const {orgMale, orgFemale} = orgAudience
    return (Number(orgMale) || 0) + (Number(orgFemale) || 0)
  }, [orgAudience])

  const orgExpectSum = useMemo(() => {
    const { male10, male20, male30, male40, male50, male60,
      female10, female20, female30, female40, female50, female60} = expAudience
    return (Number(male10) || 0) + (Number(male20) || 0)+ (Number(male30) || 0)+ (Number(male40) || 0)
      + (Number(male50) || 0)+ (Number(male60) || 0)+ (Number(female10) || 0)+ (Number(female20) || 0)
      + (Number(female30) || 0)+ (Number(female40) || 0)+ (Number(female50) || 0)+ (Number(female60) || 0)
  }, [expAudience])

  const orgFilled = useMemo(() => {
    const {org13, org18, org25, org35, org45, org55, org65, orgMale, orgFemale} = orgAudience
    const values = [org13, org18, org25, org35, org45, org55, org65, orgMale, orgFemale]

    return _.every(values, (v) => (v !== undefined && v !== ""))
  }, [orgAudience])

  const expectFilled = useMemo(() => {
    const {
      male10, male20, male30, male40, male50, male60,
      female10, female20, female30, female40, female50, female60
    } = expAudience
    return male10 && male20 && male30 && male40 && male50 && male60
      && female10 && female20 && female30 && female40 && female50 && female60
  }, [expAudience])

  const onClickExpectAudience = async () => {
    const response = await fetchExpectAudience()
    if (response.data) {
      setExpAudience(response.data)
    }
  }

  const onClickSaveAudience = async () => {
    if(!orgFilled && !expectFilled) {
      return
    }

    const payload: PutChannelAudienceRequest = {
      channelId: channelId!!
    }

    if (orgFilled) {
      payload.original = orgAudience
    }

    if (expectFilled) {
      payload.expect = expAudience
    }

    putChannelAudience(payload)
  }

  return (
    <EuiPageBody
      paddingSize="xl"
      panelled
      restrictWidth
    >
      <EuiPageHeader
        pageTitle=""
        breadcrumbs={[
          {
            text: (
              <>
                <EuiIcon size="s" type="arrowLeft"/> 뒤로
              </>
            ),
            color: 'primary',
            'aria-current': false,
            href: '#',
            onClick: (e) => e.preventDefault(),
          },
        ]}
      />
      <EuiSpacer size="xl"/>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow
            label="썸네일"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <a
              href={`https://www.youtube.com/channel/${data?.channelKey}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={data?.thumbnailUrl} style={{width: "80px", maxWidth: "100%"}}/>
            </a>
          </EuiFormRow>
          <EuiFormRow
            label="id"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="id"
              value={data?.id}
              compressed
              isLoading={isLoading}
            />
          </EuiFormRow>
          <EuiFormRow
            label="status"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="status"
              value={data?.status}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="platform"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="platform"
              value={data?.platform}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="channelKey"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="channelKey"
              value={data?.channelKey}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="name"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="name"
              value={data?.name}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="description"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="description"
              value={data?.description}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="country"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="country"
              value={data?.country}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="category"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="category"
              value={data?.category}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="channelType"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="channelType"
              value={data?.channelType}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="customUrl"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="customUrl"
              value={data?.customUrl}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="thumbnailUrl"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="thumbnailUrl"
              value={data?.thumbnailUrl}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="followerCount"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="followerCount"
              value={data?.followerCount}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="viewCount"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="viewCount"
              value={data?.viewCount}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="contentsCount"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="contentsCount"
              value={data?.contentsCount}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="tags"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="tags"
              value={data?.tags}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="syncedAt"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="syncedAt"
              value={dayjs(data?.syncedAt).format("YYYY-MM-DD")}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="contentsSyncedAt"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="contentsSyncedAt"
              value={dayjs(data?.contentsSyncedAt).format("YYYY-MM-DD")}
              compressed
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size="xxs">
            <h5>시청자층</h5>
          </EuiTitle>
          <EuiSpacer size="s"/>
          <EuiFormRow
            label="만 13 - 17세"
            helpText=""
            display="columnCompressed"
          >
            <EuiFieldText
              name="org13"
              value={orgAudience.org13 }
              onChange={(e) => {
                setOrgAudience({...orgAudience, org13: e.target.value})
              }}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="만 18 - 24세"
            helpText=""
            display="columnCompressed"
          >
            <EuiFieldText
              name="org18"
              value={orgAudience.org18}
              onChange={(e) => {
                setOrgAudience({...orgAudience, org18: e.target.value})
              }
              }
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="만 25 - 34세"
            helpText=""
            display="columnCompressed"
          >
            <EuiFieldText
              name="org25"
              value={orgAudience.org25}
              onChange={(e) => {
                setOrgAudience({...orgAudience, org25: e.target.value})
              }
              }
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="만 35 - 44세"
            helpText=""
            display="columnCompressed"
          >
            <EuiFieldText
              name="org35"
              value={orgAudience.org35}
              onChange={(e) => {
                setOrgAudience({...orgAudience, org35: e.target.value})
              }
              }
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="만 45 - 54세"
            helpText=""
            display="columnCompressed"
          >
            <EuiFieldText
              name="org45"
              value={orgAudience.org45}
              onChange={(e) => {
                setOrgAudience({...orgAudience, org45: e.target.value})
              }
              }
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="만 55 - 64세"
            helpText=""
            display="columnCompressed"
          >
            <EuiFieldText
              name="org55"
              value={orgAudience.org55}
              onChange={(e) => {
                setOrgAudience({...orgAudience, org55: e.target.value})
              }
              }
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="만 65 -"
            helpText=""
            display="columnCompressed"
          >
            <EuiFieldText
              name="org65"
              value={orgAudience.org65}
              onChange={(e) => {
                setOrgAudience({...orgAudience, org65: e.target.value})
              }}
              compressed
            />
          </EuiFormRow>

          <EuiFormRow
            label=" "
            helpText=""
            display="columnCompressed"
          >
            <>
              <EuiHorizontalRule margin="xs"/>
              {orgAgeSum || "0"}
            </>
          </EuiFormRow>


          <EuiSpacer size="m"/>
          <EuiFormRow
            label="여성"
            helpText=""
            display="columnCompressed"
          >
            <EuiFieldText
              name="orgFemale"
              value={orgAudience.orgFemale}
              onChange={(e) => {
                setOrgAudience({...orgAudience, orgFemale: e.target.value})
              }}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="남성"
            helpText=""
            display="columnCompressed"
          >
            <EuiFieldText
              name="orgMale"
              value={orgAudience.orgMale}
              onChange={(e) => {
                setOrgAudience({...orgAudience, orgMale: e.target.value})
              }}
              compressed
            />
          </EuiFormRow>

          <EuiFormRow
            label=" "
            helpText=""
            display="columnCompressed"
          >
            <>
              <EuiHorizontalRule margin="xs"/>
              {orgGenderSum || "0"}
            </>
          </EuiFormRow>

          <EuiSpacer size="xl"/>

          <EuiFormRow
            label={(
              <EuiTitle size="xxs">
                <h5>계산된 시청자층</h5>
              </EuiTitle>
            )}
            helpText=""
            display="columnCompressed"
          >
            <EuiButton
              size="s"
              color="text"
              onClick={onClickExpectAudience}
            >
              자동계산
            </EuiButton>
          </EuiFormRow>

          <EuiSpacer size="m"/>

          <EuiFormRow
            label="10대"
            helpText=""
            display="columnCompressed"
          >
            <>
              <EuiFlexGroup
                gutterSize="s"
              >
                <EuiFlexItem>
                  <EuiFieldText
                    name="male10"
                    value={expAudience.male10}
                    onChange={(e) => {
                      setExpAudience({...expAudience, male10: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
                <EuiFlexItem>

                  <EuiFieldText
                    name="female10"
                    value={expAudience.female10}
                    onChange={(e) => {
                      setExpAudience({...expAudience, female10: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          </EuiFormRow>
          <EuiFormRow
            label="20대"
            helpText=""
            display="columnCompressed"
          >
            <>
              <EuiFlexGroup
                gutterSize="s"
              >
                <EuiFlexItem>
                  <EuiFieldText
                    name="male20"
                    value={expAudience.male20}
                    onChange={(e) => {
                      setExpAudience({...expAudience, male20: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
                <EuiFlexItem>

                  <EuiFieldText
                    name="female20"
                    value={expAudience.female20}
                    onChange={(e) => {
                      setExpAudience({...expAudience, female20: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          </EuiFormRow>
          <EuiFormRow
            label="30대"
            helpText=""
            display="columnCompressed"
          >
            <>
              <EuiFlexGroup
                gutterSize="s"
              >
                <EuiFlexItem>
                  <EuiFieldText
                    name="male30"
                    value={expAudience.male30}
                    onChange={(e) => {
                      setExpAudience({...expAudience, male30: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFieldText
                    name="female30"
                    value={expAudience.female30}
                    onChange={(e) => {
                      setExpAudience({...expAudience, female30: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          </EuiFormRow>
          <EuiFormRow
            label="40대"
            helpText=""
            display="columnCompressed"
          >
            <>
              <EuiFlexGroup
                gutterSize="s"
              >
                <EuiFlexItem>
                  <EuiFieldText
                    name="male40"
                    value={expAudience.male40}
                    onChange={(e) => {
                      setExpAudience({...expAudience, male40: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
                <EuiFlexItem>

                  <EuiFieldText
                    name="female40"
                    value={expAudience.female40}
                    onChange={(e) => {
                      setExpAudience({...expAudience, female40: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          </EuiFormRow>
          <EuiFormRow
            label="50대"
            helpText=""
            display="columnCompressed"
          >
            <>
              <EuiFlexGroup
                gutterSize="s"
              >
                <EuiFlexItem>
                  <EuiFieldText
                    name="male50"
                    value={expAudience.male50}
                    onChange={(e) => {
                      setExpAudience({...expAudience, male50: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFieldText
                    name="female50"
                    value={expAudience.female50}
                    onChange={(e) => {
                      setExpAudience({...expAudience, female50: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          </EuiFormRow>
          <EuiFormRow
            label="60대"
            helpText=""
            display="columnCompressed"
          >
            <>
              <EuiFlexGroup
                gutterSize="s"
              >
                <EuiFlexItem>
                  <EuiFieldText
                    name="male60"
                    value={expAudience.male60}
                    onChange={(e) => {
                      setExpAudience({...expAudience, male60: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
                <EuiFlexItem>

                  <EuiFieldText
                    name="female60"
                    value={expAudience.female60}
                    onChange={(e) => {
                      setExpAudience({...expAudience, female60: e.target.value})
                    }}
                    compressed
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          </EuiFormRow>

          <EuiFormRow
            label=" "
            helpText=""
            display="columnCompressed"
          >
            <>
              <EuiHorizontalRule margin="xs"/>
              {orgExpectSum || "0"}
            </>
          </EuiFormRow>
          <EuiFormRow
            label={" "}
            helpText=""
            display="columnCompressed"
          >
            <EuiButton
              size="s"
              onClick={onClickSaveAudience}
            >
              저장
            </EuiButton>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPageBody>
  )
}

export default ChannelDetail

