import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonIcon,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPageBody,
  EuiPanel,
  EuiSpacer,
  EuiBadge,
  EuiAvatar,
  EuiLink,
  EuiImage,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiButtonEmpty,
  EuiSelect,
  EuiText,
  EuiLoadingSpinner,
  EuiConfirmModal,
  EuiToolTip,
} from "@elastic/eui";
import { useMemo, useState } from "react";
import { Criteria } from "@elastic/eui/src/components/basic_table/basic_table";
import moment from "moment";
import { useSearchParams, Link } from "react-router-dom";
import {
  AdminShoutoutResponseItem,
  apiDeleteShoutout,
  apiUpdateShoutout,
  GetShoutoutRequest,
  ShoutoutSendStatus,
  UpdateShoutoutRequest,
  useGetShoutouts,
} from "../../api/apis";
import { categoryMap } from "../../util/consts";
import { queryClient } from "../..";
import { useMutation } from "react-query";
import AddNewBrandModal from "../../components/AddNewBrandModal";

const ShoutoutList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isHighlightModalVisible, setIsHighlightModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isBrandModalVisible, setIsBrandModalVisible] = useState(false);
  const [selectedShoutout, setSelectedShoutout] =
    useState<AdminShoutoutResponseItem>();
  const [tempStatus, setTempStatus] = useState<ShoutoutSendStatus>();
  const [tempEmail, setTempEmail] = useState<string>("");
  const [tempHighlight, setTempHighlight] = useState<string>();

  const page: number = useMemo(() => {
    const p = searchParams.get("page");
    return p ? Number(p) - 1 : 0;
  }, [searchParams]);

  const params: GetShoutoutRequest = {
    page,
    size: 20,
  };

  const { data, isLoading } = useGetShoutouts(params);

  const pagination = {
    pageIndex: page,
    pageSize: 20,
    totalItemCount: data?.totalCount || 0,
    showPerPageOptions: false,
  };

  const { mutate: updateShoutout } = useMutation({
    mutationFn: ({
      shoutoutId,
      data,
    }: {
      shoutoutId: number;
      data: UpdateShoutoutRequest;
    }) => apiUpdateShoutout(shoutoutId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["/admin/shoutouts"]);
      queryClient.invalidateQueries(["/admin/shoutouts", page]);
      closeAllModals();
    },
  });

  const { mutate: deleteShoutout } = useMutation({
    mutationFn: (shoutoutId: number) => apiDeleteShoutout(shoutoutId),
    onSuccess: () => {
      queryClient.invalidateQueries(["/admin/shoutouts"]);
      queryClient.invalidateQueries(["/admin/shoutouts", page]);
      closeAllModals();
    },
  });

  const onTableChange = ({ page }: Criteria<AdminShoutoutResponseItem>) => {
    let nextParams = { ...params };

    if (page) {
      const { index: pageIndex } = page;
      nextParams.page = pageIndex + 1;
    }

    setSearchParams(nextParams as any);
  };

  const closeAllModals = () => {
    setIsStatusModalVisible(false);
    setIsEmailModalVisible(false);
    setIsBrandModalVisible(false);
    setIsHighlightModalVisible(false);
    setIsDeleteModalVisible(false);
  };

  const openStatusModal = (shoutout: AdminShoutoutResponseItem) => {
    setSelectedShoutout(shoutout);
    setTempStatus(shoutout.shoutoutSendStatus);
    setIsStatusModalVisible(true);
  };

  const openEmailModal = (shoutout: AdminShoutoutResponseItem) => {
    setSelectedShoutout(shoutout);
    setTempEmail(shoutout.managerEmail);
    setIsEmailModalVisible(true);
  };

  const openBrandModal = (shoutout: AdminShoutoutResponseItem) => {
    setSelectedShoutout(shoutout);
    setIsBrandModalVisible(true);
  };

  const openHighlightModal = (shoutout: AdminShoutoutResponseItem) => {
    setSelectedShoutout(shoutout);
    const highlight = shoutout.highlight
      ? parseHighlightTime(shoutout.highlight)
      : "";
    setTempHighlight(highlight);
    setIsHighlightModalVisible(true);
  };

  const openDeleteModal = (shoutout: AdminShoutoutResponseItem) => {
    setSelectedShoutout(shoutout);
    setIsDeleteModalVisible(true);
  };

  const updateStatus = () => {
    if (!selectedShoutout || !tempStatus) {
      return;
    }

    updateShoutout({
      shoutoutId: selectedShoutout.shoutoutId,
      data: {
        sendStatus: tempStatus,
      },
    });
  };

  const updateEmail = () => {
    if (!selectedShoutout || !tempEmail) {
      return;
    }

    updateShoutout({
      shoutoutId: selectedShoutout.shoutoutId,
      data: {
        managerEmail: tempEmail,
      },
    });
  };

  const updateHighlight = () => {
    if (!selectedShoutout) {
      return;
    }

    let seconds = 0;

    const highlight =
      tempHighlight
        ?.toString()
        .split(":")
        .filter((it) => it !== "") || [];
    if (highlight.length === 3) {
      seconds =
        parseInt(highlight[0]) * 3600 +
        parseInt(highlight[1]) * 60 +
        parseInt(highlight[2]);
    } else if (highlight.length === 2) {
      seconds = parseInt(highlight[0]) * 60 + parseInt(highlight[1]);
    } else if (highlight.length === 1) {
      seconds = parseInt(highlight[0]);
    } else if (highlight.length === 0) {
      seconds = 0;
    }

    updateShoutout({
      shoutoutId: selectedShoutout.shoutoutId,
      data: {
        highlight: seconds.toString(),
      },
    });
  };

  const confirmDelete = () => {
    if (!selectedShoutout) {
      return;
    }

    deleteShoutout(selectedShoutout.shoutoutId);
  };

  const columns: Array<EuiBasicTableColumn<AdminShoutoutResponseItem>> = [
    {
      field: "shoutoutId",
      name: "ID",
      sortable: true,
      width: "50px",
    },
    {
      field: "channelName",
      name: "채널",
      sortable: true,
      width: "200px",
      render: (_, shoutout: AdminShoutoutResponseItem) => (
        <Link to={`/channels/${shoutout.channelId}`}>
          <EuiFlexGroup alignItems="center" gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiAvatar
                size="m"
                name={shoutout.channelName}
                imageUrl={shoutout.channelAvatarUrl}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText size="s">{shoutout.channelName}</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Link>
      ),
    },
    {
      field: "brandName",
      name: "브랜드",
      sortable: true,
      width: "120px",
      render: (_, shoutout: AdminShoutoutResponseItem) => (
        <EuiLink onClick={() => openBrandModal(shoutout)}>
          <EuiFlexGroup alignItems="center" gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiAvatar
                size="m"
                name={shoutout.brandName}
                imageUrl={shoutout.brandLogoUrl}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText size="s">{shoutout.brandName}</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiLink>
      ),
    },
    {
      field: "videoTitle",
      name: "비디오",
      sortable: true,
      width: "260px",
      render: (_, shoutout: AdminShoutoutResponseItem) => {
        const t = shoutout.highlight ? `&t=${shoutout.highlight}` : "";
        const url = `https://www.youtube.com/watch?v=${shoutout.videoKey}${t}`;
        return (
          <a href={url} target="_blank">
            <EuiFlexGroup alignItems="center" gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiImage
                  size="s"
                  alt={shoutout.videoTitle}
                  url={shoutout.videoThumbnailUrl || ""}
                  style={{ width: "80px", height: "45px" }}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText
                  size="s"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {shoutout.videoTitle}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </a>
        );
      },
    },
    {
      field: "highlight",
      name: "언급시간",
      sortable: true,
      width: "80px",
      render: (
        highlight: number | null,
        shoutout: AdminShoutoutResponseItem
      ) => (
        <EuiLink onClick={() => openHighlightModal(shoutout)}>
          {highlight ? parseHighlightTime(highlight) : "edit"}
        </EuiLink>
      ),
    },
    {
      field: "managerEmail",
      name: "담당자",
      sortable: true,
      width: "120px",
      render: (email: string, shoutout: AdminShoutoutResponseItem) => (
        <EuiToolTip content={email || "담당자 없음"}>
          <EuiLink onClick={() => openEmailModal(shoutout)}>
            <div
              style={{
                maxWidth: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {email || "edit"}
            </div>
          </EuiLink>
        </EuiToolTip>
      ),
    },
    {
      field: "shoutoutSendStatus",
      name: "상태",
      sortable: true,
      width: "100px",
      render: (
        status: ShoutoutSendStatus | undefined,
        shoutout: AdminShoutoutResponseItem
      ) => {
        let color = "default";

        if (status) {
          switch (status) {
            case "SENT":
              color = "success";
              break;
            case "WONT_SEND":
              color = "default";
              break;
            case "WAITING":
              color = "default";
              break;
          }
        }

        return (
          <EuiLink onClick={() => openStatusModal(shoutout)}>
            {status && <EuiBadge color={color}>{status || "edit"}</EuiBadge>}
            {!status && "edit"}
          </EuiLink>
        );
      },
    },
    {
      field: "shoutoutCreatedAt",
      name: "시간",
      sortable: true,
      width: "82px",
      render: (timestamp: string) => {
        const date = moment(timestamp);
        const today = moment().startOf("day");

        return date.isSame(today, "day")
          ? date.format("HH:mm")
          : date.format("YYYY-MM-DD");
      },
    },
    {
      name: "액션",
      width: "50px",
      actions: [
        {
          name: "삭제",
          description: "Shoutout 삭제",
          icon: "trash",
          type: "icon",
          color: "danger",
          onClick: (shoutout: AdminShoutoutResponseItem) =>
            openDeleteModal(shoutout),
        },
      ],
    },
  ];

  return (
    <EuiPageBody paddingSize="xl" panelled>
      {isLoading ? (
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          style={{ height: "200px" }}
        >
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size="xl" />
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <EuiBasicTable
          items={data?.items || []}
          columns={columns}
          pagination={pagination}
          onChange={onTableChange}
          compressed
        />
      )}

      {isStatusModalVisible && selectedShoutout && (
        <EuiModal onClose={closeAllModals}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>상태 변경</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiFormRow label="상태">
              <EuiSelect
                options={[
                  { value: "WAITING", text: "WAITING" },
                  { value: "SENT", text: "SENT" },
                  { value: "WONT_SEND", text: "WONT_SEND" },
                ]}
                value={tempStatus}
                onChange={(e) =>
                  setTempStatus(e.target.value as ShoutoutSendStatus)
                }
              />
            </EuiFormRow>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeAllModals}>취소</EuiButtonEmpty>
            <EuiButton fill onClick={updateStatus}>
              저장
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}

      {isEmailModalVisible && selectedShoutout && (
        <EuiModal onClose={closeAllModals}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>담당자 이메일 변경</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiFormRow label="이메일">
              <EuiFieldText
                value={tempEmail}
                onChange={(e) => setTempEmail(e.target.value)}
              />
            </EuiFormRow>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeAllModals}>취소</EuiButtonEmpty>
            <EuiButton fill onClick={updateEmail}>
              저장
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}

      {isBrandModalVisible && selectedShoutout && (
        <AddNewBrandModal
          initialData={{
            brandId: parseInt(selectedShoutout.brandId),
            brandName: selectedShoutout.brandName,
            logoUrl: selectedShoutout.brandLogoUrl,
            categoryCode: selectedShoutout.brandCategory || undefined,
            status: selectedShoutout.brandStatus,
          }}
          onClose={(brandId?: number) => {
            closeAllModals();
            if (brandId) {
              queryClient.invalidateQueries(["/admin/shoutouts"]);
              queryClient.invalidateQueries(["/admin/shoutouts", page]);
            }
          }}
        />
      )}

      {isHighlightModalVisible && selectedShoutout && (
        <EuiModal onClose={closeAllModals}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>언급시간 변경</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiFormRow label="언급시간 (초)">
              <EuiFieldText
                placeholder="12:30"
                value={!!tempHighlight ? tempHighlight : ""}
                onChange={(e) => {
                  const value = e.target.value;
                  setTempHighlight(value);
                }}
              />
            </EuiFormRow>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeAllModals}>취소</EuiButtonEmpty>
            <EuiButton fill onClick={updateHighlight}>
              저장
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}

      {isDeleteModalVisible && selectedShoutout && (
        <EuiConfirmModal
          title="Shoutout 삭제"
          onCancel={closeAllModals}
          onConfirm={confirmDelete}
          cancelButtonText="취소"
          confirmButtonText="삭제"
          buttonColor="danger"
          defaultFocusedButton="confirm"
        >
          <p>
            ID: {selectedShoutout.shoutoutId}, 채널:{" "}
            {selectedShoutout.channelName}
            <br />
            브랜드: {selectedShoutout.brandName}, 비디오:{" "}
            {selectedShoutout.videoTitle}
            <br />
            <br />이 Shoutout을 삭제하시겠습니까?
          </p>
        </EuiConfirmModal>
      )}
    </EuiPageBody>
  );
};

const parseHighlightTime = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;
  if (hours > 0) {
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  } else if (minutes > 0) {
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  } else {
    return `0:${seconds}`;
  }
};

export default ShoutoutList;
